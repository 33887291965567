<template>
  <div v-if="!isLoading">
    <b-card class="p-lg-1 mx-5" v-for="(orderLabel, index) in orderLabels" :key="index">
      <div class="row justify-content-center align-items-center">
        <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6 mt-3">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon
              v-if="orderLabel.payment_status == 'paid'"
              icon="CheckIcon"
              size="400"
              class="text-success"
            />
            <feather-icon
              v-else
              icon="XCircleIcon"
              size="400"
              class="text-warning"
            />
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <h1 v-if="orderLabel.payment_status == 'paid'">Payment Successful !</h1>
            <h1 v-else>Payment Failed !</h1>
          </div>
          <div
            v-if="orderLabel.payment_status == 'paid'"
            class="d-flex justify-content-center align-items-center"
          >
            <h3>Order created.</h3>
          </div>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
          <div class="d-flex mt-4">
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center mr-3"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.id }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Order ID
              </h5>
            </b-card>
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ $helpers.formatTotal(orderLabel.amount) }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Amount
              </h5>
            </b-card>
          </div>
          <div class="d-flex">
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center mr-3"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.order_placed_date.substr(0, 10) }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Order Placed
              </h5>
            </b-card>
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.delivery_date.substr(0, 10) }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Delivery Date
              </h5>
            </b-card>
          </div>
          <div class="d-flex">
            <b-card
              class="border w-100 d-flex justify-content-center align-items-center"
            >
              <div>
                <h3 class="d-flex justify-content-center align-items-center">
                  Payment Status
                </h3>
                <div
                class="d-flex justify-content-center align-items-center"
                :class="{
                  'text-success': orderLabel.payment_status == 'paid',
                  'text-warning': orderLabel.payment_status != 'paid'
                }"
              >
                <strong>{{
                  (orderLabel.payment_status == "paid"
                    ? "PAID"
                    : orderLabel.paymentRemarks || 'PENDING')
                }}</strong>
              </div>
              </div>
              <div
                v-if="orderLabel.payment_status != 'paid'"
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <b-button
                  variant="gradient-primary"
                  class="box-shadow-1"
                  @click="retryPay(orderLabel.id)"
                  >Click here to try again</b-button
                >
              </div>
            </b-card>
          </div>
          <div class="d-flex">
            <b-card
              class="border w-100 d-flex justify-content-center align-items-center"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                DELIVERY ADDRESS
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.recipient.complete_address }}
              </h5>
            </b-card>
          </div>
        </div>
      </div>
    </b-card>
    <div class="d-flex justify-content-center align-items-center">
      <b-button
        variant="gradient-primary"
        class="box-shadow-1 px-5"
        href="/Distributor-Bulk-delivery"
        >Continue Ordering</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      payment: "",
      paymentStatus: "",
      orderLabels: [],
    };
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    fetchOrders() {
      const orderIds = this.$route.query.payment_id
        ? this.$route.query.payment_id.split("_")
        : this.$route.query.order_id.split("_");
      this.isLoading = true;
      orderIds.forEach((id) => {
        this.$http
          .get(`get_order/${id}`)
          .then((response) => {
            if (response.status) {
              this.orderLabels.push(response.data.data);
            }
            if (orderIds.indexOf(id) === orderIds.length - 1) {
              this.isLoading = false;
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      });
    },
    checkPayment() {
      this.orderLabels.forEach((order, index) => {
        this.$http
          .get(`check_order_payment_status/${order.id}`)
          .then((response) => {
            // Assuming you want to add the payment status and remarks directly to the order object
            this.$set(this.orderLabels, index, {
              ...order,
              paymentStatus: response.data.data.status,
              paymentRemarks: response.data.data.remarks,
            });
          })
          .catch((error) => {
            console.error(
              "Failed to check payment status for order ID:",
              order.id,
              error
            );
            // Handle error, maybe set a default status or remark
          });
      });
    },
    generateLabel() {
      this.$refs["generateLabel"].show();
    },

    downloadInvoice() {
      this.$refs["downloadInvoice"].show();
    },

    continueOrdering() {
      this.$router.replace("/outlet-order");
    },

    retryPay() {
      console.log("test");
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-width: 3px !important;
}
</style>
